import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../images/icons/p27.svg";
import safe_icon from "../images/skicka_faktura_safe.png";
import scandinavia_img from "../images/scandinavia.svg";

const BOT_CODE = "kxFV5wCG";

function Betalningar_p27() {
    const [chatbot_1, setChatbot_1] = useState(false);
    const [chatbot_boka_demo_2, set_chatbot_boka_demo_2] = useState(false);
    

    return(
        <Layout>
            <Helmet>
                <title>Payments P27 | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="P27 compatibility with Crediflow" />
                <meta name="keywords" content="Payments P27" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="P27 compatibility with Crediflow" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/payments-p27" />
                <link rel="cannonical" href="https://www.crediflow.com/payments-p27" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">P27 compatibility with Crediflow</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        P27 is a joint initiative by Danske Bank, Handelsbanken, 
                                        Nordea, OP Financial Group, SEB and Swedbank to 
                                        establish a Nordic payment infrastructure for 
                                        domestic and cross-border payments in the Nordic currencies and euros.
                                        <br/><br/>
                                        Crediflow follows developments closely and 
                                        we are already helping companies and 
                                        partners to convert to and support ISO 20022.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="Payments P27 compatibility with Crediflow" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">payments-p27</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">A Nordic venture</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                P27 Nordic Payments Platform is a Nordic investment 
                                where the goal is to create a common modern and 
                                future-proof infrastructure for payments in 
                                the Nordics. It is a bank-joint project where 
                                the purpose is to promote trade within and 
                                between the Nordic countries, among other 
                                things, by enabling real-time payments across borders.
                                <br/><br/>
                                The goal of P27 is more efficient payments in the 
                                Nordic currencies and the euro with one system. 
                                Standardization is one of the main objectives of 
                                P27 and therefore P27 is based on the ISO 20022 format.
                            </p>
                            <div className="mt-10 flex">
                                <Link to="/demo" className="px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                                <a href="https://nordicpayments.eu/" target="_blank" rel="noreferrer"className="ml-3 px-10 py-2 rounded-full text-blue-custome border border-blue-custome bg-white shadow-md hover:bg-blue-100">Read more</a>
                            </div>
                            
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={scandinavia_img} alt="payments-p27 with Crediflow" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={safe_icon} alt="P27-projekt" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Timeline</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                The P27 project will continue for several years 
                                and take place in several stages. The first part 
                                of the migration concerns payments in Swedish 
                                kronor within Sweden and runs during 2022-2024.
                                <br/><br/>
                                For more information and which bank 
                                connections already exist, contact info@crediflow.se
                            </p>
                            <button onClick={() => set_chatbot_boka_demo_2(true)} className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                            {chatbot_boka_demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={BOT_CODE} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Betalningar_p27;